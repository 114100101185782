.settingsInterlocutor
  cursor: pointer
  display: flex
  width: 100%
  padding: 12px
  align-items: center
  gap: 1rem
  &.current-user
    background-color: #e7e7e7
    border-radius: 10px
  &:hover
    box-shadow: 0px 0px 10px 0px #0000001a
    border-radius: 10px
  p
    font-family: $mainFontLight
    display: flex
    align-items: center
    justify-content: center
    background-color: white
    border-radius: var(--main-border-radius)
    height: 40px
    padding: 10px
  &__content
    display: flex
    width: 100%
    align-items: center
    flex-wrap: wrap
    justify-content: space-between
    font-size: 0.8vw
    &_name
      width: 35%
      &_civility
        width: 10%
      &_first
        width: 25%
      &_last
        width: 25%
    &_function
      gap: 0.5rem
      width: 20%
      &_inp
        width: 100%
    &_mail
      gap: 0.5rem
      width: 30%
      &_inp
        width: 100%
    &_initials
      gap: 0.5rem
      width: 10%
      &_inp
        width: 100%

.setInterlocuteurInp
  justify-content: space-between
  display: flex
  align-items: center
  gap: 0.5rem
  label
    min-width: max-content
  p
    min-width: max-content

.setInterlocuteurBtn
  background-color: var(--main-color-orange)
  padding: 10px
  p
    display: block
  svg
    padding: 0
    border-radius: 0

.interloButton
  height: 100%
  img
    // padding: 15px
    height: 40px
