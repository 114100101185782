.validPop
  position: fixed
  z-index: 10000
  top: 50%
  left: calc(50% + 125px)
  transform: translate(-50%, -50%)
  box-shadow: $mainBoxShadow
  display: flex
  flex-direction: column
  align-items: center
  background-color: #fff
  padding: 2rem
  border-radius: $mainBorderRadius
  &__title
    margin-bottom: 10px
  &__desc
  &__buttonContainer
    margin-top: 2rem
    gap: 1rem
    width: 100%
    display: flex
    justify-content: space-between
    &_backButton
      background-color: $mainBlue
      &:hover
        background-color: $mainBlueHover
    &_continueButton
