.loginFormPosition
  display: flex
  justify-content: center
  margin-bottom: 2rem
.copyright
  text-align: center
  font-size: 0.8rem
  color: #666666
.loginForm
  margin: 3rem 0 2rem 0
  background-color: $mainGray
  border-radius: $mainBorderRadius
  //position: absolute
  padding: 2rem 2rem
  width: 80%
  max-height: 700px
  min-height: 600px
  height: calc(70% + 40px)
  display: flex
  flex-direction: column
  justify-content: space-between
  &__title
    width: 100%
    font-family: $mainFontMedium !important
    text-align: center
    text-transform: uppercase
    font-size: 24px
    font-weight: 700 !important
    &_span
      font-family: $mainFontLight !important
      font-weight: 300 !important
  &__line
    border: none
    background-color: gray
    height: 2px
    border-radius: 15px
    margin: 2rem 0 0 0
    width: 100%
  &__content
    display: grid
    grid-template-columns: 1fr 1fr
    justify-items: start
    gap: 60px
    &_textContainer
      max-width: 315px
      display: flex
      flex-direction: column
      align-items: end
      line-height: 1.2
      gap: 24px
      text-align: end
      justify-self: end
      &_title
        font-family: $mainFontMedium
      &_subtitle
        font-family: $mainFont
        font-size: 1em
        font-style: italic
      &_text
        font-family: $mainFontLight
        line-height: 19.5px
        text-align: end
        &_span
          font-family: $mainFontBold
      &_button
        background-color: rgba(31, 52, 73, 0.1)
        border: none
        padding: 8px 12px
        display: flex
        align-items: center
        gap: 8px
        border-radius: 5px
        img
          width: 12px
        &:hover
          cursor: pointer

    &_form
      width: fit-content
      &_errorMessage
        color: red
        font-size: 0.8rem
        margin-top: 5px
      &_inputContainer
        display: flex
        //width: 10rem !important
        flex-direction: column
        label
          margin: 10px 0
          color: #666666
          font-family: $mainFontLight
          font-size: 14px
          line-height: 14px
        &.first-label
          label
            margin-top: 0
        input
          //width: 100% !important
          width: 270px !important
          //width: 100%
      &_button
        width: 100%
        //width: 15rem !important
        margin-top: 1rem
      &_link
        margin-top: 10px
        font-size: 0.8rem
        font-family: $mainFontMedium
        text-align: end
        cursor: pointer
        &:hover
          text-decoration: underline
      &_left-link
        margin-top: 10px
        font-size: 0.8rem
        font-family: $mainFontMedium
        text-align: start
        cursor: pointer
        &:hover
          text-decoration: underline
      &_checkbox-container
        display: block
        position: relative
        margin: 1.5rem 0
        padding-left: 25px
        cursor: pointer
        line-height: 12px
        font-family: $mainFontMedium
        &:hover input ~ .checkmark
          background-color: #ccc
        input
          position: absolute
          opacity: 0
          cursor: pointer
          height: 0
          width: 0
          &:checked ~ .checkmark
            background-color: transparent
          &:checked ~ .checkmark:after
            display: block
        .checkmark
          position: absolute
          top: 0
          left: 0
          height: 16px
          width: 16px
          border-radius: 50%
          border: 1px solid black
          background-color: white
          &:after
            content: ""
            position: absolute
            top: 2px
            left: 2px
            height: 10px
            width: 10px
            background-color: $mainOrange
            border-radius: 50%
            display: none
            &:hover checkmark
              background-color: #ccc
