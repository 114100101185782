.editingInfos
  margin-bottom: 3rem
  &__titleContainer
    margin-bottom: 2rem
    &_title
      +sectionTitle
    &_line
      border: none
      height: 3px
      background-color: $lineColor
  &__firstSection
    margin-bottom: 1rem
    display: flex
    justify-content: space-between
    gap: 2rem
    &_nameContainer
      width: 100%
      &_title
        +sectionSubtitle
      &_input
        padding: 1rem
        border-radius: $mainBorderRadius
        display: flex
        align-items: center
        width: 100%
    &_interContainer
      width: 100%
      &_title
        +sectionSubtitle
      &_content
        align-items: center
      &_select
        border: none
        border-bottom: 1px solid black
        padding: 1rem
        width: 100%
        &:hover
          cursor: pointer
        &_option
  &__contact
    &_title
      +sectionSubtitle
      display: flex
      flex-direction: column
      gap: 10px
  &__secondSection
    display: flex
    gap: 2rem
    width: 100%
    div
      width: 100%
