.invoiceGroup
  &__titleContainer
    margin-bottom: 2rem
    &_title
      +sectionTitle
    &_line
      border: none
      height: 3px
      background-color: $lineColor
  &__groupContainer
    &_groupHeader
      display: flex
      justify-content: space-between
      align-items: center
      gap: 30px
      &_arrowGroup
        gap: 10px
        display: flex
        &_arrow
          cursor: pointer
          padding: 5px
          background-color: $mainBlue
          border-radius: 3px
          height: 18px
          path
            fill: $white
        &_deleteButton
          background-color: white
          border: 1px solid red
          border-radius: $mainBorderRadius
          font-size: 100%
          cursor: pointer
          height: 28px
          img
            background-color: #fff
            padding: 6px
      &_input
        padding: 13px
        border-radius: $mainBorderRadius
        display: flex
        align-items: center
        width: 40%
        &::placeholder
          text-transform: uppercase
      &_total
        text-transform: uppercase
        display: flex
        flex-direction: column
        justify-content: end
        gap: 6px
        margin-left: auto
        p
          text-align: right
          font-weight: 700
          line-height: normal
          letter-spacing: normal
        &_discount
          color: $mainOrange
          span
            color: $mainOrange
    &_addLineButton
      cursor: pointer
      margin-top: 1rem
      display: flex
      justify-content: center
      align-items: center
      background-color: #fff
      border: none
      padding: 0.5rem 1rem
      width: 100%
      &_icon
        background-color: $mainBlue
        border-radius: 50%
        padding: 10px
        height: 1.5rem
        width: 1.5rem
        path
          fill: $white
