// colors
$mainBlue: #1f3449
$mainOrange: #F6A817
$white: #fff
$navGray: #ffffff94
$mainBorderColor: #E3E3E3
$mainGray: #f4f3f3
$stateLineGray: #dddddd
$lineColor: #666666
$mainBlueHover: #3c5b80
//stateColors
$green: #34C71C
$red: #F62417
$orange: $mainOrange
$blue: $mainBlue
$purple: #800080

$grayBlurBg: rgba(128, 128, 128, 0.5)
// borders
$mainBorderRadius: 5px

// fonts
$mainFontLight: "Wigrum-Light", 'Poppins', sans-serif
$mainFont: "Wigrum-Regular", 'Poppins', sans-serif
$mainFontMedium: "Wigrum-Medium", 'Poppins', sans-serif
$mainFontBold: "Wigrum-Bold", 'Poppins', sans-serif
//$mainFont: 'Poppins', sans-serif

// paddings
$navPadding: 15px
$listPagePadding: 0 40px

// boxShadows
$mainBoxShadow: 0 0 10px 0 rgba(0, 0, 0, 0.2)

@mixin listPagePadding($pt: 0px)
  padding: $pt 40px 0 40px

@mixin listPagePadding($pt: 0px)
  padding: $pt 40px 0 40px

@mixin sectionTitle()
  margin-bottom: 1rem
  font-size: 24px
  font-weight: bold
  text-transform: uppercase

@mixin sectionSubtitle()
  margin-bottom: 1rem
  font-size: 18px
  font-weight: bold
