.loaderContainer
  top: 0
  position: absolute
  background-color: $grayBlurBg
  height: 100%
  min-height: 100vh
  width: 100%
  z-index: 10000
  &__loader
    position: absolute
    top: 50%
    left: 45%
    border: 5px solid #f4f3f3
    border-top: 5px solid #fdab0d
    border-radius: 50%
    width: 80px
    height: 80px
    animation: spin 1s ease-in-out infinite