.itemPop
    position: fixed
    z-index: 9999
    overflow: hidden
    top: 50%
    left: 58%
    transform: translate(-50%, -50%)
    background: var(--main-color-grey)
    border: 1px solid #f0eff4
    padding: 2rem
    border-radius: 5px
    width: 60%
    &__header
        margin-bottom: 0.5rem
        display: flex
        justify-content: space-between
        &_options
            display: flex
            align-items: center
            gap: 15px
            &_icon
                height: 2rem
        &_title
            font-size: 1.5rem
            font-weight: 600
            color: var(--main-color-black)
    &__name-input
        margin: 1rem 0
        width: 50%
        input
            width: 100% !important