.loginFooter
  border-radius: $mainBorderRadius
  background-color: $mainBlue
  padding: 2rem
  height: 7rem
  display: flex
  align-items: center
  justify-content: space-between
  &__leftContainer
    display: flex
    align-items: center
    gap: 1rem
    height: 7rem
    &_logo
      cursor: pointer
      width: 126px
  &__rightContainer
    justify-self: end
    &_txt
      color: #CBCCD0
      font-size: 10px
    &_button
      height: fit-content
      padding: 10px !important
      margin-left: 2rem
      background-color: rgba(254, 254, 254, 0.2) !important
      line-height: 12px
      svg
        font-size: 1rem
        margin-left: 10px !important
        path