.priceRecap
  width: 100%
  &__discount
    margin: 1rem 0
    &_title
      font-weight: 550
      margin-bottom: 10px
      font-size: 15px
    &_priceContainer
      display: flex
      justify-content: space-between
      align-items: center
      &_input
        padding: 1rem
        border-radius: $mainBorderRadius
      &_price
