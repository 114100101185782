.total
  background-color: $mainBlue
  display: flex
  justify-content: space-between
  align-items: center
  border-radius: $mainBorderRadius
  padding: 1rem
  margin: 5px 0
  &__title
    font-weight: 550
    color: $white
  &__price
    font-size: 1.2rem
    color: $white
    white-space: nowrap
