.accountant
  &__title
  &__group
    cursor: pointer
    margin: 1rem 0
    background-color: var(--main-color-grey)
    padding: 1rem
    border-radius: 10px
    display: flex
    max-width: min-content
    flex-direction: row
    justify-content: space-between
    align-items: center
    &:hover
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2)
      border-radius: 10px
    &_content
      width: max-content
      display: flex
      align-items: center
      padding: 1rem
      flex-wrap: wrap
      gap: 20px
      p
        font-family: $mainFontLight
      &_name
        width: fit-content
        display: flex
        align-items: center
        p
          display: inline-block
          margin-right: 1rem
          align-items: center
          justify-content: center
          background-color: white
          border-radius: var(--main-border-radius)
          height: 40px
          padding: 10px
          min-width: 130px
        label
          display: inline-block
          min-width: max-content
          margin-right: 1rem
          text-align: center
      &_mail
        display: flex
        align-items: center
        p
          display: inline-block
          margin-right: 1rem
          align-items: center
          justify-content: center
          background-color: white
          border-radius: var(--main-border-radius)
          height: 40px
          padding: 10px
          min-width: 200px
