*
  //font-family: $mainFontMedium
  letter-spacing: 0.5px

input
  border: 0.5px solid $mainBlue

.main
  margin-left: 250px
  width: calc(100% - 250px)
  position: relative
  @media screen and (max-width: 1024px)
    width: 100%

.lightFont
  font-family: $mainFontLight !important

.mediumFont
  font-family: $mainFontMedium !important

.boldFont
  font-family: $mainFontBold !important

.stateGray
  color: gray

.stateBlue
  color: $blue

.stateOrange
  color: $orange
  path
    fill: $orange

.stateGreen
  color: $green
  path
    fill: $green !important

.stateRed
  color: $red
  path
    fill: $red !important

.statePurple
  color: $purple

.react-calendar
  position: absolute
  z-index: 100 !important
  left: 50%
  top: 50vh
  transform: translate(-50%, -50%)

.grayBlur
  overflow: hidden
  position: absolute
  z-index: 70
  background-color: rgba(128, 128, 128, 0.5)
  height: 100%
  width: 100%

.container
  display: block
  width: 100%
  max-width: 100%
  margin-left: auto
  margin-right: auto
  padding-right: 15px

.activeBlurBg
  +activeBlurBg
  position: fixed
  z-index: 95
  top: 0
  left: 0
  width: 100%
  height: 100vh

// listHeader
.listPage
  margin: 2rem 0 0 0
  display: flex
  flex-direction: column
  width: 100%
  +listPagePadding
  &__filterContainer
    margin-bottom: 3rem

// cards
.cards
  width: 100%
  user-select: none
  margin-bottom: 1rem
  padding: 1rem
  border: 1px solid $mainBorderColor
  border-radius: $mainBorderRadius
  display: flex
  +cardBoxShadowHover

  &__contentContainer
    width: 100%

    &_titleContainer
      display: flex

      &_title
        font-size: 1.5em
        margin: 0

        &_light
          font-family: $mainFontLight
          font-weight: 400
          text-transform: uppercase

        &_span
          font-weight: bold

        &_initials
          font-style: italic

      &_stateContainer
        display: flex
        align-items: center

        &_state
          margin-left: 2rem
          background-color: $mainBlue
          color: $white
          padding: 5px
          font-weight: 550
          text-transform: uppercase
          border-radius: $mainBorderRadius

        &_icon
          margin-left: 5px

    &_name
      font-family: $mainFontMedium
      margin: 10px 0
      text-transform: uppercase
      font-weight: 550

    &_function
      margin: 10px 0
      text-transform: uppercase
      font-weight: 650

    &_content
      display: flex
      align-items: center

      &_name
        margin: 10px 0
        text-transform: uppercase
        font-weight: 550

      &_price

      &_ht
        color: $mainOrange
        font-weight: 550
        font-size: 1.2rem

      &_bar
        margin: 0 10px

      &_date

      &_icon
        margin-right: 5px

        path
          fill: $mainOrange
  &__options
    height: fit-content
    display: flex
    transition: border-radius 0.3s ease
    &_content
      padding: 10px
      z-index: 5
      position: absolute
      right: 55px
      opacity: 0
      width: 0
      height: 0
      //background-color: #fff
      overflow: hidden
      border-radius: 0
      transition: all .3s ease
      display: flex
      flex-direction: column
      align-items: flex-start
      gap: 5px
      &_button
        text-align: start
        font-size: 1.1rem
        cursor: pointer
        text-transform: capitalize
        border: none
        font-family: $mainFontLight
        background-color: #fff
        &:hover
          text-decoration: underline
        &_icon
          margin-right: 5px
      .editButton
        font-family: $mainFontMedium
      .deleteButton
        font-family: $mainFontMedium
        color: red
        svg
          path
            fill: red
      &_line
        margin: 5px 0
        border: none
        height: 1px
        background-color: $mainGray
        border-radius: 15px
        width: 100%
    .optionsContentActive
      right: 90px
      background-color: $white
      opacity: 1
      margin-right: 1rem
      width: 15rem
      border-radius: 10px
      height: fit-content
      transition: all .3s ease, opacity .1s ease
      box-shadow: $mainBoxShadow
    &_optionsButton
      z-index: 10
      height: 15px
      background-color: $mainGray
      border-radius: 8px
      padding: 7px
      width: 15px
      transition: all .3s ease
    .optionButtonActive
      border-radius: 50%
      transition: all .3s ease
  .optionsActive
    border-radius: 10px
    transition: all .3s ease
  &__contactContainer
    width: 100%
    display: flex
    flex-direction: row
    path
      fill: $mainOrange
    &_titleContainer
      width: 50%
      display: flex
      flex-direction: column
      gap: 0.6rem
      &_title
        display: flex
        flex-direction: row
        &_name
          font-family: $mainFontLight
          font-size: 1.5em
        &_stateContainer
          display: flex
          align-items: center

          &_state
            margin: 0 1rem
            background-color: $mainBlue
            color: $white
            padding: 5px
            font-weight: 550
            text-transform: uppercase
            border-radius: $mainBorderRadius
    &_society
      font-size: 1.3rem
      font-family: $mainFontMedium
    &_address
      display: flex
      flex-direction: row
      gap: 1rem
      font-family: $mainFontLight
      p
        font-family: $mainFontLight
      &_city
        font-family: $mainFontMedium
        text-transform: uppercase
    &_phone-number
      display: flex
      flex-direction: row
      gap: 1rem
      p
        font-family: $mainFontLight
.button
  padding: 15px
  height: 55px

.showOptions
//background-color: red

.garbage
  background-color: red
  border-radius: 10px
  border: 0

  svg
    height: 25px

    path
      fill: white

  &:hover
    cursor: pointer
    background-color: rgb(208, 20, 20)

@media screen and (max-width: 1024px)
  .main
    margin-left: 0

.invisible
  visibility: hidden

.itemPopGarbage
  height: 1em !important

.contactInfoPanelw-0-5
  width: calc(50% - 1rem) !important
